// src/components/RegistrationPage.js

import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { API, graphqlOperation } from "aws-amplify";
import { createRegistration } from "../graphql/mutations";
import { listRegistrations } from "../graphql/queries";


const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    attendedBefore: false,
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const userExists = async (email) => {
    try {
      const result = await API.graphql(
        graphqlOperation(listRegistrations, {
          filter: { email: { eq: email } },
        })
      );
      return result.data.listRegistrations.items.length > 0;
    } catch (error) {
      console.error("Error checking user existence:", error);
      return false;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (await userExists(formData.email)) {
        // Show error message if the user is already registered
        toast({
          title: "Registration failed.",
          description: "You have already registered for this event.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const result = await API.graphql(
        graphqlOperation(createRegistration, { input: formData })
      );
  
      // Clear the form
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        attendedBefore: false,
      });
  
      // Show success message
      toast({
        title: "Registration successful!",
        description:
          "Thank you for registering. You will receive a text message soon with more details about the party.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error registering:", error);
      toast({
        title: "Registration failed.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Flex
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="gray.100"
    >
      <Box
        padding="6"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        backgroundColor="white"
        textAlign="center"
        width={["100%", "100%", "80%", "50%"]}
      >
        <Heading as="h2" size="lg" marginBottom="6">
          RSHWUB Presents: A Speakeasy Murder
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="firstName" marginBottom="6">
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="lastName" marginBottom="6">
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="phoneNumber" marginBottom="6">
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="email" marginBottom="6">
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="attendedBefore" marginBottom="6">
            <Checkbox
              name="attendedBefore"
              isChecked={formData.attendedBefore}
              onChange={handleCheckboxChange}
            >
              I've attended an RSHWUB Murder Mystery Party before
            </Checkbox>
          </FormControl>
          <Button colorScheme="blue" type="submit">
            Register
          </Button>
        </form>
      </Box>
    </Flex>
  );
};

export default RegistrationPage;
