// src/components/UserProfile.js

import React, { useState, useEffect } from "react";
import countryData from "country-telephone-data";
import {
  Box,
  Flex,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  SimpleGrid,
  Toast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Auth } from "aws-amplify";

const UserProfile = () => {
  // State for user attributes
  const [attributes, setAttributes] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    genderPreference: "",
    username: "",
  });

  // State for edit mode
  const [editMode, setEditMode] = useState(false);

  // State for telephone country
  const [selectedCountry, setSelectedCountry] = useState(null);

  // Format Country Data
  const formatCountryData2 = () => {
    return countryData.allCountries.map((country) => ({
      value: country.dialCode,
      label: `${country.name} (${country.dialCode})`,
    }));
  };

  // Fetch user attributes on mount
  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAttributes((currentAttributes) => ({
          ...currentAttributes,
          name: user.attributes.name || "",
          email: user.attributes.email || "",
          phoneNumber: user.attributes.phone_number || "",
          genderPreference: user.attributes["custom:gender_preference"] || "",
          username: user.username || "",
        }));
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleChange = (e) => {
    setAttributes({
      ...attributes,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: attributes.name,
        email: attributes.email,
        phone_number: selectedCountry
          ? `+${selectedCountry.value}${attributes.phoneNumber}`
          : attributes.phoneNumber,
        "custom:gender_preference": attributes.genderPreference,
        username: attributes.username,
      });

      // Show success message
      Toast({
        title: "Profile updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Exit edit mode
      toggleEditMode();
    } catch (error) {
      console.error("Error updating user attributes:", error);

      // Show error message
      Toast({
        title: "An error occurred while updating your profile.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const renderField = (label, name, value, type = "text") => {
    if (editMode) {
      return (
        <FormControl id={name} marginBottom="6">
          <FormLabel>{label}</FormLabel>
          <Input
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
          />
        </FormControl>
      );
    } else {
      return (
        <Box marginBottom="6">
          <Text fontWeight="bold">{label}:</Text>
          <Text>{value}</Text>
        </Box>
      );
    }
  };

  return (
    <Flex
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="gray.100"
    >
      <Box
        padding="6"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        backgroundColor="white"
        textAlign="center"
        width={["100%", "100%", "80%", "50%"]}
      >
        <Heading as="h2" size="lg" marginBottom="6">
          User Profile
        </Heading>
        <form onSubmit={handleSubmit}>
          <SimpleGrid columns={[1, 1, 2]} spacing="4">
            {renderField("Name", "name", attributes.name)}
            {renderField("Email", "email", attributes.email, "email")}
            {editMode && (
              <Grid templateColumns="repeat(2, 1fr)" gap={4} marginBottom="6">
                <GridItem colSpan={1}>
                  <FormControl id="countryCode" isRequired>
                    <FormLabel>Country Code</FormLabel>
                    <FormControl id="countryCode" marginBottom="6">
                      <FormLabel>Country Code</FormLabel>
                      <ReactSelect
                        options={formatCountryData2()}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        isSearchable
                        placeholder="Select a country code"
                      />
                    </FormControl>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="phoneNumber" isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      type="tel"
                      placeholder="Enter your Phone Number"
                      name="phoneNumber"
                      value={attributes.phoneNumber}
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            )}
            {renderField("Username", "username", attributes.username)}
            {editMode && (
              <FormControl id="genderPreference" marginBottom="6">
                <FormLabel>Character Gender Preference</FormLabel>
                <Select
                  name="genderPreference"
                  value={attributes.genderPreference}
                  onChange={handleChange}
                >
                  <option value="none">No Preference</option>
                  <option value="F">Female Characters</option>
                  <option value="M">Male Characters</option>
                </Select>
              </FormControl>
            )}
            {!editMode && (
              <Box marginBottom="6">
                <Text fontWeight="bold">Character Gender Preference:</Text>
                <Text>
                  {attributes.genderPreference === "F"
                    ? "Female Characters"
                    : attributes.genderPreference === "M"
                    ? "Male Characters"
                    : "No Preference"}
                </Text>
              </Box>
            )}
          </SimpleGrid>
          <Flex justifyContent="center" marginTop="6">
            {!editMode && (
              <Button colorScheme="blue" onClick={toggleEditMode}>
                Edit
              </Button>
            )}
            {editMode && (
              <>
                <Button colorScheme="blue" type="submit" marginRight="4">
                  Save
                </Button>
                <Button variant="outline" onClick={toggleEditMode}>
                  Cancel
                </Button>
              </>
            )}
          </Flex>
        </form>
      </Box>
    </Flex>
  );
};

export default UserProfile;
